import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-c769d46e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "project-table",
  style: {
    "margin-top": "20px"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_table_column = _resolveComponent("el-table-column");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_table = _resolveComponent("el-table");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_table, {
    data: $data.tableData,
    "row-key": "id",
    border: "",
    "header-cell-style": {
      'text-align': 'center',
      'color': '#999999',
      'padding': '16px 0',
      'font-size': '16px',
      'background-color': '#F6F6F6'
    },
    "cell-style": {
      'text-align': 'center',
      'padding': '16px 0'
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_el_table_column, {
      prop: "course_name",
      label: "课程名称"
    }), _createVNode(_component_el_table_column, {
      fixed: "right",
      label: "环节总数"
    }, {
      default: _withCtx(scope => [_createVNode(_component_el_button, {
        link: "",
        type: "primary",
        onClick: $event => $options.showEdit(scope.row.course_id, '')
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(scope.row.course_link_num), 1)]),
        _: 2
      }, 1032, ["onClick"])]),
      _: 1
    }), _createVNode(_component_el_table_column, {
      fixed: "right",
      label: "已评价环节数"
    }, {
      default: _withCtx(scope => [_createVNode(_component_el_button, {
        link: "",
        type: "primary",
        onClick: $event => $options.showEdit(scope.row.course_id, 1)
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(scope.row.finished_num), 1)]),
        _: 2
      }, 1032, ["onClick"])]),
      _: 1
    }), _createVNode(_component_el_table_column, {
      fixed: "right",
      label: "未评价环节数"
    }, {
      default: _withCtx(scope => [_createVNode(_component_el_button, {
        link: "",
        type: "primary",
        onClick: $event => $options.showEdit(scope.row.course_id, 0)
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(scope.row.not_eva_num), 1)]),
        _: 2
      }, 1032, ["onClick"])]),
      _: 1
    })]),
    _: 1
  }, 8, ["data"])])])]);
}