import "core-js/modules/es.array.push.js";
import { getActiveCourseEva } from "@/api/api";
export default {
  name: "visitorList",
  data() {
    return {
      tableData: [],
      operator_server_active_id: '',
      dialogFormVisible: false
    };
  },
  mounted() {
    let data = this.$root.useRoute.query;
    if (data.id) {
      this.operator_server_active_id = data.id;
      this.getList();
    }
  },
  methods: {
    getList() {
      getActiveCourseEva({
        operator_server_active_id: this.operator_server_active_id
      }).then(res => {
        this.tableData = res.data;
      });
    },
    showEdit(id, state) {
      this.$root.useRouter.push({
        path: "/admin/activeManage/activeEvaCourseLinkList",
        query: {
          operator_server_active_id: this.operator_server_active_id,
          course_id: id,
          check_state: state
        }
      });
    }
  }
};